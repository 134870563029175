// shared styles
.main-div {
  overflow: auto;
  height: 100%;
}
.string-label {
  font: normal normal bold 18px/32px "Questrial";
}

// notifications styles
.grid-container-email-phone-labels {
  display: grid;
  grid-template-areas: "left right right right right";
  margin-bottom: 30px;
  margin-top: 10px;
}
.email-block {
  grid-area: left;
}
.phone-block {
  grid-area: right;
}
.string-value {
  font: normal normal normal 18px/32px "Questrial";
}
.notifications-checkbox {
  grid-column: 2 / 3;
  justify-content: right;
  padding-right: 10px;
  padding-top: 10px;
  height: 41px;
}
.notifications-label {
  grid-column: 1 / 2;
  padding-left: 10px;
  padding-top: 5px;
  height: 41px;
  font: normal normal normal 18px/32px "Questrial";
}
.notifications-label-grey {
  grid-column: 1 / 2;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  font: normal normal normal 18px/32px "Questrial";
  padding-left: 10px;
  padding-top: 5px;
  height: 41px;
}
.notifications-checkbox-grey {
  grid-column: 2 / 3;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  justify-content: right;
  padding-right: 10px;
  padding-top: 10px;
  height: 41px;
}
.grid-notifications-table {
  display: grid;
  grid-template-columns: [label] 300px [checkbox] 50px;
}
.grid-notifications-table-phone-view {
  display: grid;
  grid-template-columns: [label] 250px [checkbox] 50px;
}
.frequency-dropdown {
  width: 282px;
  height: 40px;
  font: normal normal normal 18px/24px "Questrial";
}
.frequency-label {
  font: normal normal bold 18px/32px "Questrial";
  margin-top: 40px;
  margin-bottom: 10px;
}

// account styles
.bad-phone-entry {
  border: 2px solid red;
}
.profile-picture-account-page {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #ebebeb;
}
.grid-profile-picture-button {
  display: grid;
  grid-template-columns: [image] 100px [buttons] 240px;
}
.inline-buttons {
  display: inline-block;
  width: 103px;
  height: 41px;
  margin-right: 10px;
}
.grid-label-inputs {
  display: grid;
  grid-template-columns: [label] 250px [input] 500px;
}
.input-labels {
  font: normal normal bold 24px/32px "Questrial";
  margin-top: 30px;
}
.input-values {
  font: normal normal normal 24px/32px "Questrial";
  margin-top: 30px;
}
.change-password {
  font: normal normal normal 16px/32px "Questrial";
  color: #0061d5;
  padding-left: 0px;
}
.margin-left-buttons {
  // margin-top: 15px;
  margin-left: 10px;
}
.no-class-here {
  margin-top: 0px;
}
@media (max-width: 576px) {
  .input-labels {
    font: normal normal bold 18px/32px "Questrial";
    margin-top: 10px;
  }
  .input-values {
    font: normal normal normal 18px/32px "Questrial";
    margin-top: 0px;
  }
  .inline-buttons {
    display: inline-block;
    width: 93px;
    height: 38px;
  }
  .grid-profile-picture-button {
    display: grid;
    grid-template-columns: [image] 80px [buttons] 220px;
  }
}
