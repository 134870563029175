// Profile
.field-name {
  font: normal normal bold 24px/32px "Questrial";
  min-width: 200px;
}

.field-value {
  font: normal normal normal 24px/32px "Questrial";
}

.profile-username {
  font: normal normal normal 36px/32px "Questrial";
  margin-bottom: 30px;
}
.profile-row {
  margin-bottom: 15px;
}

.profile-picture {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #ebebeb;
}

@media (max-width: 576px) {
  .profile-picture {
    height: 100px;
    width: 100px;
    display: block;
    margin: 15px auto;
  }
  .field-name {
    min-width: 100px;
  }
  .profile-username {
    justify-content: center;
  }
  .profile-row {
    justify-content: space-between;
  }
}
