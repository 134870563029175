@media (max-width: 576px) {
  // FAB
  .rtf--mb__c {
    padding: 0;
    margin: 0;
  }

  .ovention-header {
    padding: 0;
  }
}
