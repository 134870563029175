.ovenDetails-col {
  padding: 0 50px;
  margin: 0px auto;
  height: 100%;
  overflow-y: scroll;
}
@media (max-width: 576px) {
  .ovenDetails-col {
    padding: 0;
  }
}
