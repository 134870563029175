@import "../../config/colors.less";

// Single Dropdown
.dropdown-title {
  text-align: left;
  font: normal normal bold 16px/24px Questrial;
  letter-spacing: 0px;
  color: #000000;
}
.dropdown-text {
  text-align: left;
  font: normal normal normal 18px/24px Questrial;
  letter-spacing: 0px;
  color: #000000;
}

.dropdown-mini-block > div {
  display: table;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #414142;
  border-radius: 2px;
}

// Page Title
.title-grid-container {
  display: grid;
  grid-template-areas: "left center right";
  grid-gap: 10px;
  justify-content: space-between;
}
.orgLogo {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: right;
  height: 70px;
  width: 250px;
}
.title-page-text {
  grid-area: left;
  letter-spacing: 0px;
  color: @primary-color;
  font: normal normal bold 57px/64px Questrial;
}
.title-back-btn {
  float: left;
  cursor: pointer;
  // To align  create new oven form at the center of the heading
  min-width: 20px;
  max-width: 20px;
  // To align the "Back" text and arrow
  display: flex;
  align-items: center;
}
//Tablet
@media (max-width: 992px) {
  .title-page-text {
    font-size: 38px;
  }
}

@media (max-width: 576px) {
  .arrow-back-button {
    height: 36px;
    width: 36px;
  }

  .title-back-btn {
    // Set to Back-Button width
    max-width: 36px;
  }

  .title-grid-container {
    display: grid;
    grid-template-columns: left center right;
    .title-page-text {
      font-size: 32px;
      grid-area: center;
    }
  }
  .orgLogo {
    display: none;
  }
}

// UploadButton
.upload-fileList-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 10px 10px;
  line-height: 36px;
  font-size: 16px;
  color: #1890ff;
  margin-bottom: 15px;
  > .delete-icon {
    color: rgb(189, 35, 49);
    cursor: pointer;
  }
  > span .document-icon {
    height: 36px;
    margin-right: 10px;
  }
}
// Profile Menu
.profile-menu {
  padding: 10px 10px 20px 10px;
  border-radius: 8px;
  > .ant-dropdown-menu-item {
    font-size: 16px;
  }
}

//PSWrapper
@ps-margin-top: 60px;
.settings-menu-main-div {
  margin-top: @ps-margin-top;
  overflow: auto;
  height: calc(100% - @ps-margin-top);
}
.settings-string {
  grid-area: left;
  font: normal normal bold 28px/32px "Questrial";
  letter-spacing: 0px;
  color: #000000;
}
.save-changes-button {
  grid-area: right;
  width: 156px !important;
  font: normal normal bold 18px/24px "Questrial";
  letter-spacing: 0px;
}
.grid-container {
  display: grid;
  grid-template-areas: "left right";
  justify-content: space-between;
  margin-bottom: 20px;
}
.save-changes-button-phone-view {
  font: normal normal bold 18px/24px "Questrial";
  letter-spacing: 0px;
}

@media (max-width: 576px) {
  @ps-margin-top: 20px;
  .settings-menu-main-div {
    margin-top: @ps-margin-top;
    overflow: auto;
    height: calc(100% - @ps-margin-top);
  }
}

//Confirm-Button
.confirm-modal {
  > .ant-modal-content {
    border-radius: 16px;
    > .ant-modal-body {
      background-color: @ovn-primary-light !important;
      border-radius: 16px;
    }
  }
}
.confirm-warning-txt {
  font-size: 24px;
  font-weight: bold;
}
.confirm-sec-txt {
  font-size: 18px;
}
@media (max-width: 576px) {
  .confirm-warning-txt {
    font-size: 16px;
    font-weight: bold;
  }
  .confirm-sec-txt {
    font-size: 12px;
  }
}
