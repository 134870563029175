.add-icon {
  height: 70px;
}

.updates-checkbox-div {
  margin-top: 16px;
}
.oven-cards-wrapper {
  margin-left: 0px !important;
  margin-top: 50px;
  margin-right: 100px !important;
  overflow-y: auto;
  flex-grow: 1;
}
.oven-add-btn {
  width: 100%;
  height: 166px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8a2b !important;
  border-radius: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  cursor: pointer;
}

// Tablet
@media (max-width: 992px) {
  .oven-cards-wrapper {
    margin-right: 50px !important;
  }
}

@media (max-width: 576px) {
  .oven-add-btn {
    height: 60px;
  }
  .add-icon {
    height: 44px;
  }
  .oven-cards-wrapper {
    margin-left: 0px !important;
    margin-top: 10px !important;
    margin-right: 0px !important;
  }
}
// UploadRecipe Page
.upload-recipe-wrapper {
  justify-content: center;
  margin-top: 12px;
  row-gap: 16px;
  column-gap: 64px;
  height: 100%;
  width: 100%; //for box-sizing to work
  overflow: hidden scroll;
}
.loading-wrapper.active {
  text-align: center;
  font-size: 30px;
  color: rgb(251, 176, 66);
  > #loading {
    margin: 20px auto;
    width: 30%;
  }
  .error {
    color: rgb(243, 86, 77);
  }
}

.spin-wrapper {
  flex-grow: 1;
  overflow: hidden;
  > .ant-spin-container {
    height: 100%;
    overflow: hidden;
  }
}
@media (max-width: 576px) {
  .spin-wrapper {
    float: left;
  }
  .loading-wrapper.active {
    margin: 50px auto;
    text-align: center;
    font-size: 18px;
  }
}

.page-title {
  font: normal normal bold 57px/64px "Questrial";
  color: #ff8a2b;
  text-align: center;
}
.form-wrapper {
  justify-content: center;
}
.main-form {
  width: 100%;
  max-width: 250px;
  > .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-form-item-explain.ant-form-item-explain-connected {
    min-height: 0px;
  }
}
@media (max-width: 576px) {
  .page-title {
    font: normal normal bold 28px/32px "Questrial";
    color: #ff8a2b;
    text-align: center;
  }
  .form-wrapper {
    margin: 20px;
    justify-content: flex-start;
  }
  .main-form {
    max-width: 100%;
  }
}
