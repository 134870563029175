@import "../../config/colors.less";

// error Table
.errorTable {
  .ant-table-tbody > tr > td {
    font-size: 18px;
  }

  .ant-table-tbody > tr > td:first-of-type {
    text-align: center;
  }
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #707070;
    font-size: 16px;
    font-weight: bold;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }
}

// Oven Details
.edit-lcation-button-box {
  display: flex;
  justify-content: space-between;
}
.od-store-name-wrapper{
  display: flex;
  align-items: center
}
.od-store-name {
  color: @primary-color;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0px;
  text-align: center;
}
.od-store-details {
  text-align: center;
  line-height: 2;
  font-size: 18px;
}
.oven-params {
  font-size: 18px;
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.od-update-icon {
  position: absolute;
  height: 18px;
  border-radius: 18px;
  background-color: #ffffff;
  top: -10px;
  right: -5px;
}
@media (max-width: 576px) {
  .od-store-name-wrapper{
    align-self: flex-start;
    min-width: 100%
  }
  .od-store-name {
    font-size: 24px;
    flex-grow: 1;
  }
}
.oven-card-name {
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal bold 36px/32px Questrial;
  letter-spacing: 0px;
  color: #000000;
}
.oven-card-model {
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal bold 18px/24px Questrial;
  letter-spacing: 0px;
  color: #000000;
}
.left-float {
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.right-float {
  float: right;
}
.card-data-content {
  font: normal normal normal 18px/24px Questrial;
  letter-spacing: 0px;
  color: #000000;
}
.mini-grid-container {
  display: grid;
  grid-template-areas: "left right";
  grid-gap: 0px;
  justify-content: space-between;
}
.mini-grid-container-main {
  display: grid;
  grid-template-areas: "left right";
  grid-gap: 0px;
  justify-content: space-between;
  height: 38px;
  margin-bottom: 8px;
}
.ant-card-body {
  padding: 16px;
}

// Oven Card
.oven-card-active {
  background-color: @ovn-primary-light !important;
}

// Oven Popup
.oven-popup {
  > .ant-modal-content {
    border-radius: 18px;
    > .ant-modal-body {
      background-color: @ovn-primary-light !important;
      border-radius: 18px;
    }
  }
  min-width: 872px;
  font-size: 18px;
}
.op-col-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.oven-popup-close {
  height: 36px;
  width: 36px;
  position: absolute;
  right: -18px;
  border-radius: 18px;
  top: -18px;
  background-color: @ovn-primary-light;
}
.op-mobile-header {
  display: none;
}
.op-data-border {
  border-right: 1px solid @ovn-secondary-color;
}
// Tablet
@media (max-width: 992px) {
  .oven-popup {
    min-width: 650px;
  }
  .op-data-border {
    border-right: none;
    padding-bottom: 16px;
    border-bottom: 1px solid @ovn-secondary-color;
  }
}

@media (max-width: 576px) {
  .ant-modal-mask {
    z-index: -60;
  }
  .oven-popup {
    // Stretching the modal
    top: 64px;
    min-width: 100%;
    min-height: calc(100% - 64px);
    padding-bottom: 0px;
    margin: 0px;
    overflow-x: hidden;

    > .ant-modal-content {
      border-radius: 0;
      > .ant-modal-body {
        background-color: #ffffff !important;
        border-radius: 0;
      }
    }
  }
  .op-data-border {
    padding-bottom: 15px;
    border-right: none;
    border-bottom: 1px solid @ovn-secondary-color;
  }
  .op-col-title {
    color: @primary-color;
  }
  .op-mobile-header {
    display: flex;
  }
}
